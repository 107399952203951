<template>
  <v-container class="general">
    <PageTitle :component="'GroupTransactions'" />

    <template v-if="!isLoading">
      <div
        v-if="stripeConnectedAccount.details_submitted"
        class="mb-5"
      >
        <span
          class="sw-accent font-weight-bold cursor-pointer"
          @click="openProfileDialog()"
          >{{ $t("group_transactions.edit_information_label") }}</span
        >
      </div>

      <div
        v-if="stripeConnectedAccount.details_submitted"
        class="mb-5"
      >
        <div id="notification-banner-container"></div>
      </div>

      <div
        v-if="!stripeConnectedAccount.details_submitted"
        class="mb-5"
      >
        <div id="account-onboarding-container"></div>
      </div>

      <div
        v-if="
          stripeConnectedAccount.details_submitted &&
          stripeConnectedAccount.charges_enabled
        "
      >
        <div class="mb-5">
          <div id="balances-container"></div>
        </div>
        <div id="payments-container"></div>
      </div>
    </template>

    <v-dialog
      v-model="dialog"
      width="800"
      persistent
      scrollable
    >
      <v-card class="border-radius">
        <v-card-actions>
          <v-spacer />
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon small>close</v-icon>
          </v-btn>
        </v-card-actions>
        <div class="mb-3 text-center">
          <div class="sw-h4">
            {{ $t("group_transactions.edit_information_dialog_title") }}
          </div>
          ({{ stripeConnectedAccount.id }})
        </div>
        <v-card-text class="pb-5 text-xs-center">
          <template v-if="dialog">
            <div id="account-management-container"></div>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { loadConnectAndInitialize } from "@stripe/connect-js";

export default {
  data: () => ({
    stripeConnectedAccount: {},
    stripeConnectInstance: null,
    dialog: false,
    isLoading: false,
  }),
  computed: {
    stripePublishableKey() {
      return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    },
    activeGroup() {
      return this.$store.getters.activeGroup || {};
    },
    groupId() {
      return this.activeGroup.id;
    },
    appLanguageIso() {
      return this.$store.getters.appLanguage;
    },
    stripeComponentOptions() {
      if (!this.groupId) {
        return null;
      }

      return {
        publishableKey: this.stripePublishableKey,
        fetchClientSecret: this.fetchClientSecret,
        appearance: {
          variables: {
            colorPrimary: "#008f8c",
            colorText: "#1f363d",
            buttonPrimaryColorBackground: "#008f8c",
            buttonPrimaryColorText: "#FFFFFF",
            buttonSecondaryColorBackground: "#FFFFFF",
            buttonSecondaryColorBorder: "#008f8c",
            buttonSecondaryColorText: "#008f8c",
            borderRadius: "10px",
            buttonBorderRadius: "24px",
            formBorderRadius: "0px",
            badgeBorderRadius: "0px",
            overlayBorderRadius: "0px",
            spacingUnit: "10px",
            // fontFamily: "Arial, sans-serif",
            fontSizeBase: "14px",
            headingXlFontSize: "16px",
            headingXlFontWeight: "400",
            headingLgFontSize: "16px",
            headingLgFontWeight: "400",
            headingMdFontSize: "16px",
            headingMdFontWeight: "400",
            headingSmFontSize: "14px",
            headingSmFontWeight: "400",
            headingXsFontSize: "14px",
            headingXsFontWeight: "400",
            bodyMdFontSize: "14px",
            bodyMdFontWeight: "400",
            bodySmFontSize: "14px",
            bodySmFontWeight: "400",
            labelMdFontSize: "14px",
            labelMdFontWeight: "400",
            labelSmFontSize: "14px",
            labelSmFontWeight: "400",
          },
        },
        locale: this.appLanguageIso,
      };
    },
  },
  watch: {
    groupId: {
      handler() {
        this.checkAccountStatus();
      },
    },
    appLanguageIso: {
      handler() {
        this.checkAccountStatus();
      },
    },
  },
  mounted() {
    this.checkAccountStatus();
  },
  methods: {
    openProfileDialog() {
      this.dialog = true;

      this.$nextTick(() => {
        if (!this.stripeConnectedAccount.details_submitted) {
          return null;
        }

        this.loadAccountManagementComponent();
      });
    },
    async checkAccountStatus() {
      if (!this.groupId) {
        return null;
      }

      try {
        this.isLoading = true;

        const response = await this.$api.groupPayments.fetch_client_account(
          this.groupId,
        );

        this.isLoading = false;

        this.stripeConnectedAccount = response.data.data;

        if (!this.stripeComponentOptions) {
          return null;
        }

        this.stripeConnectInstance = loadConnectAndInitialize(
          this.stripeComponentOptions,
        );

        this.$nextTick(() => {
          if (!this.stripeConnectedAccount.details_submitted) {
            this.loadAccountOnboardingComponent();
          }

          if (this.stripeConnectedAccount.details_submitted) {
            this.loadNotificationsComponent();

            if (this.stripeConnectedAccount.charges_enabled) {
              this.loadBalancesComponent();
              this.loadTransactionsComponent();
            }
          }
        });
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async fetchClientSecret() {
      try {
        const response = await this.$api.groupPayments.fetch_client_secret(
          this.groupId,
        );

        if (!response.data.client_secret) {
          return;
        }

        return response.data.client_secret;
      } catch (error) {
        if (error) {
          this.errorMessageShow(error);
        }
      }
    },
    loadNotificationsComponent() {
      const container = document.getElementById(
        "notification-banner-container",
      );
      const component = this.stripeConnectInstance.create(
        "notification-banner",
      );

      container.appendChild(component);
    },
    loadBalancesComponent() {
      const container = document.getElementById("balances-container");
      const component = this.stripeConnectInstance.create("balances");

      container.appendChild(component);
    },
    loadTransactionsComponent() {
      const container = document.getElementById("payments-container");
      const component = this.stripeConnectInstance.create("payments");

      container.appendChild(component);
    },
    loadAccountOnboardingComponent() {
      const container = document.getElementById("account-onboarding-container");
      const component = this.stripeConnectInstance.create("account-onboarding");

      component.setOnExit(() => this.checkAccountStatus());

      container.appendChild(component);
    },
    loadAccountManagementComponent() {
      const container = document.getElementById("account-management-container");
      const component = this.stripeConnectInstance.create("account-management");

      container.appendChild(component);
    },
  },
};
</script>

<style scoped></style>
